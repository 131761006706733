import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { mutationAuth, mutationChangeLostPassword, mutationDemandResetPassword, mutationResetPassword, mutationSingIn } from '@api';
import { SUPER_USER_KEY } from '@constants';
import { AuthData } from '@constants/auth';
import { environment } from '@env/environment';
import { SessionStorage } from '@services/api';
import { AccountStorage, ApiService } from '@services/core';
import { NotifyService, RedirectService } from '@services/shared';
import { CoreSchema, Mutation, MutationResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private api: ApiService,
    private accountStorage: AccountStorage,
    private sessionStorage: SessionStorage,
    private redirectService: RedirectService,
    private http: HttpClient,
    private notifyService: NotifyService,
  ) {}

  public isLoggedIn = false;
  auth() {
    this.isLoggedIn = this.sessionStorage.checkCredentials();
    let i = window.location.href.indexOf('code');
    if (!this.isLoggedIn) {
      if (i != -1) {
        this.retrieveToken(window.location.href.substring(i + 5));
      } else {
        this.login();
      }
    } else {
      const currentId = this.sessionStorage.getOrgId();
      if (currentId) {
        this.sessionStorage.setOrgId(currentId);
        this.accountStorage.getOrganizationsList();
        this.accountStorage.getOrganizationById(currentId);
        if (!this.accountStorage.getAccount()?.id) {
          if (localStorage.getItem(SUPER_USER_KEY) === 'false') {
            this.accountStorage.getUser(currentId);
          } else {
            this.accountStorage.setSuperUserAccount();
            this.accountStorage.updateRoles(['owner']);
          }
        }
      }
    }
  }

  signIn(variables: CoreSchema.MutationSignInArgs): MutationResult<'signIn'> {
    return this.api.mutate<Mutation<'signIn'>, CoreSchema.MutationSignInArgs>({
      mutation: mutationSingIn,
      variables,
    });
  }

  login() {
    const redirect = `${window.location.origin}/auth/login`;
    window.location.href = `${environment.authUrl}/oauth2/authorize?response_type=code&client_id=erp&redirect_uri=${redirect}&scope=owner`;
  }

  retrieveToken(code: string) {
    let headers = new HttpHeaders({
      Authorization: 'Basic ' + btoa('erp:kjsfh8au3pjNSdp9ubfjbPF8wUb0iajsd09GBHuyt56dtfC45G'),
    });

    const body = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', code)
      .set('redirect_uri', `${window.location.origin}/auth/login`);

    this.http.post<AuthData>(`${environment.authUrl}/oauth2/token`, body, { headers: headers }).subscribe((res: AuthData) => {
      if (res) {
        this.sessionStorage.saveToken(res);
        this.accountStorage.getOrganizations();
      }
    });
  }

  logout(): void {
    if (!this.sessionStorage.isAccessTokenValid()) {
      this.sessionStorage
        .updateTokens()
        .then(() => {
          this.logoutAuth();
        })
        .catch(() => {
          this.clear();
        });
    } else {
      this.logoutAuth();
    }
  }

  logoutAuth() {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.sessionStorage.getAccessToken(),
    });

    this.http.get(`${environment.authUrl}/signout`, { headers }).subscribe(
      (_) => {
        this.clear();
      },
      () => {
        this.notifyService.addNotification({
          type: 'alert',
          title: 'Ошибка при выходе',
        });
      },
    );
  }

  clear() {
    this.sessionStorage.clear();
    this.api.closeSocket();
    this.accountStorage.clear();
    localStorage.clear();

    window.location.reload();
  }

  loginAndSetTokens(variables: CoreSchema.MutationAuthArgs): MutationResult<'auth'> {
    return this.api.mutate<Mutation<'auth'>, CoreSchema.MutationAuthArgs>({
      mutation: mutationAuth,
      variables,
    });
  }

  changeLostPassword(variables: CoreSchema.MutationChangeLostPasswordArgs): MutationResult<'changeLostPassword'> {
    return this.api.mutate<Mutation<'changeLostPassword'>, CoreSchema.MutationChangeLostPasswordArgs>({
      mutation: mutationChangeLostPassword,
      variables,
    });
  }

  lostPassword(variables: CoreSchema.MutationLostPasswordArgs): MutationResult<'demandResetPassword'> {
    return this.api.mutate<Mutation<'demandResetPassword'>, CoreSchema.MutationLostPasswordArgs>({
      mutation: mutationDemandResetPassword,
      variables,
    });
  }

  resetPassword(variables: CoreSchema.MutationResetPasswordArgs): MutationResult<'resetPassword'> {
    return this.api.mutate<Mutation<'resetPassword'>, CoreSchema.MutationResetPasswordArgs>({
      mutation: mutationResetPassword,
      variables,
    });
  }

  setupAuthClient(): void {
    // need to restart socket graphql
    this.api.closeSocket();
  }
}
