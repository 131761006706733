import { Injectable, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

import { StoresStorage } from '@app/modules/settings/services';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { MAX_CHARACTERS, RootNavigationRoute, ROUTE_CREATE_NEW, SettingsRoute } from '@constants';
import { FormConfirmSaveService, UtilsService, ValidationErrorsService } from '@core/service';
import { SessionStorage } from '@services/api';
import { SidenavService } from '@services/shared';
import {
  MutationResult,
  PageRequestInput,
  PaymentSystem,
  PaymentSystemCreateInput,
  PaymentSystemForm,
  PaymentSystemPage,
  PaymentSystemUpdateInput,
  QueryPaymentSystemsArgs,
  Store,
} from '@typings';

import { PaySystemsStorage } from './pay-systems.storage';

@Injectable({
  providedIn: 'root',
})
export class PaySystemsService {
  form: FormGroup<PaymentSystemForm>;
  modalRef: ModalRef<unknown>;
  paymentSystem: PaymentSystem;
  isEditing$ = new BehaviorSubject(false);
  isSubmitDisabled$ = new BehaviorSubject(false);
  refreshList$ = new BehaviorSubject<boolean>(true);

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private sessionStorage: SessionStorage,
    private paySystemsStorage: PaySystemsStorage,
    private storesStorage: StoresStorage,
    private validationErrorsService: ValidationErrorsService,
    private sidenavService: SidenavService,
    private modalService: ModalService,
    private formConfirmSaveService: FormConfirmSaveService,
    private utilsService: UtilsService,
  ) {}

  getStores(): Observable<Store[]> {
    return this.storesStorage.getAllStoresForPaymentSystem();
  }

  async initForm(paymentSystem: PaymentSystem, storeId: string | undefined): Promise<void> {
    this.paymentSystem = paymentSystem;

    this.form = this.fb.group<PaymentSystemForm>({
      name: this.fb.control(null, [Validators.required, Validators.maxLength(MAX_CHARACTERS.PAYMENT_SYSTEM_NAME)]),
      type: this.fb.control(null, [Validators.required]),
      icon: this.fb.control(null, [Validators.required]),
      storeIds: this.fb.control(
        storeId ? [storeId] : await lastValueFrom(this.getStores()).then((stores) => stores.map((store) => store.id)),
      ),
    });

    if (paymentSystem) {
      const { controls } = this.form;
      const { name, type, icon, stores } = paymentSystem;

      controls.name.setValue(name ? name : null);
      controls.type.setValue(type ? type : null);
      controls.icon.setValue(icon ? icon : null);
      controls.storeIds.setValue(stores.map((store) => store.id));
    }

    this.isEditing$.next(Boolean(paymentSystem));
    this.isSubmitDisabled$.next(false);

    this.formConfirmSaveService.setForm(this.form);
  }

  openPaymentSystemPage(id?: string) {
    this.router.navigate([
      this.sessionStorage.getOrgId(),
      RootNavigationRoute.settings,
      SettingsRoute.paymentSystems,
      id || ROUTE_CREATE_NEW,
    ]);
  }

  getPaymentSystemList(
    pageRequest: PageRequestInput,
    storeIds?: string[],
    search?: string,
    isSystem: boolean = true,
  ): Observable<PaymentSystemPage> {
    const args: QueryPaymentSystemsArgs = { pageRequest };
    const hasStoresFilter = !!storeIds?.length;
    if (hasStoresFilter || !!search || !isSystem) {
      args.filter = {};
      if (!!search) {
        args.filter.search = search;
      }
      if (hasStoresFilter) {
        args.filter.storeIds = storeIds;
      }

      // if (!isSystem) {
      //   args.filter.isSystem = isSystem;
      // }
    }
    return this.paySystemsStorage.getPaymentSystemList(args);
  }

  getPaymentSystem(id: string): Observable<PaymentSystem> {
    return this.paySystemsStorage.getPaymentSystem({ paymentSystemId: id });
  }

  back(shouldConfirm: boolean = true): void {
    this.sidenavService.back(shouldConfirm);
  }

  submitForm(): void {
    if (this.form.invalid) {
      this.validationErrorsService.markFormControls(this.form);

      return;
    }

    this.disableForm();

    this.paymentSystem ? this.updatePaymentSystem() : this.createPaymentSystem();
  }

  disableForm(): void {
    this.form.disable();
    this.isSubmitDisabled$.next(true);
  }

  enableForm(): void {
    this.form.enable();
    this.isSubmitDisabled$.next(false);
  }

  createPaymentSystem(): void {
    this.paySystemsStorage.createPaymentSystem({ paymentSystemCreateInput: this.createPaymentSystemCreateInput() }).subscribe(
      () => this.back(false),
      () => this.enableForm(),
    );
  }

  createPaymentSystemCreateInput(): PaymentSystemCreateInput {
    const { name, type, icon, storeIds } = this.form.controls;

    return {
      name: name.value!,
      type: type.value!,
      icon: icon.value!,
      storeIds: storeIds.value,
    };
  }

  updatePaymentSystem(): void {
    this.paySystemsStorage.updatePaymentSystem({ input: this.createPaymentSystemUpdateInput() }).subscribe(
      () => this.back(false),
      () => this.enableForm(),
    );
  }

  createPaymentSystemUpdateInput(): PaymentSystemUpdateInput {
    const { name, type, icon } = this.form.controls;
    const storeIds = this.form.controls.storeIds.value || [];
    const prevStoreIds = this.paymentSystem.stores.map((store) => store.id) || [];
    const diffStoreIds = this.utilsService.getListsDiff(prevStoreIds, storeIds);

    const paymentSystemUpdateInput: PaymentSystemUpdateInput = { id: this.paymentSystem.id };

    if (name.value !== this.paymentSystem.name) {
      paymentSystemUpdateInput.name = name.value;
    }

    if (type.value !== this.paymentSystem.type) {
      paymentSystemUpdateInput.type = type.value;
    }

    if (icon.value !== this.paymentSystem.icon) {
      paymentSystemUpdateInput.icon = icon.value;
    }

    if (diffStoreIds.added.length) {
      paymentSystemUpdateInput.addStoreIds = diffStoreIds.added;
    }

    if (diffStoreIds.removed.length) {
      paymentSystemUpdateInput.removeStoreIds = diffStoreIds.removed;
    }

    return paymentSystemUpdateInput;
  }

  showModal(modalTemplate: TemplateRef<unknown>): void {
    this.modalRef = this.modalService.openDialog(modalTemplate);
  }

  hideModal(): void {
    this.modalRef.close();
  }

  cancelModal() {
    this.modalRef.close(false);
  }

  okModal() {
    this.modalRef.close(true);
  }

  deletePaymentSystem(id: string): MutationResult<'deletePaymentSystem'> {
    return this.paySystemsStorage.deletePaymentSystem({ id });
  }

  refreshList(): void {
    this.refreshList$.next(true);
  }
}
