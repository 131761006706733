import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ColorThemeService {
  private renderer: Renderer2;
  private colorScheme: string;
  private colorSchemePrefix = 'theme-';
  logoUrl: BehaviorSubject<string> = new BehaviorSubject<string>('assets/images/logo-apos.svg');

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  _detectPrefersColorScheme() {
    if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      this.colorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      localStorage.setItem('system-color', this.colorScheme);
    } else {
      this.colorScheme = 'dark';
    }
    this.logoUrl.next(this.colorScheme === 'dark' ? 'assets/images/logo-dark.svg' : 'assets/images/logo-apos.svg');
  }

  _setColorScheme(scheme: string) {
    this.colorScheme = scheme;
    localStorage.setItem('prefers-color', scheme);
    this.logoUrl.next(scheme === 'dark' ? 'assets/images/logo-dark.svg' : 'assets/images/logo-apos.svg');
  }

  _getColorScheme() {
    const localStorageColorScheme = localStorage.getItem('prefers-color');
    if (localStorageColorScheme && localStorageColorScheme !== 'system') {
      this.colorScheme = localStorageColorScheme;
      this.logoUrl.next(this.colorScheme === 'dark' ? 'assets/images/logo-dark.svg' : 'assets/images/logo-apos.svg');
    } else {
      this._detectPrefersColorScheme();
    }
  }

  load() {
    this._getColorScheme();
    this.renderer.addClass(document.body, this.colorSchemePrefix + this.colorScheme);
  }

  update(scheme: string) {
    this._setColorScheme(scheme);
    this.renderer.removeClass(document.body, this.colorSchemePrefix + (this.colorScheme === 'dark' ? 'light' : 'dark'));
    this.renderer.addClass(document.body, this.colorSchemePrefix + scheme);
  }

  currentActive() {
    return this.colorScheme;
  }
}
