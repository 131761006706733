import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import {
  AuthRoute,
  CatalogRoute,
  MarketingRoute,
  RootNavigationRoute,
  ROUTE_CREATE_NEW,
  ROUTE_IMPORT,
  SettingsRoute,
  SettingsSubscriptionsRoute,
  WarehouseRoute,
} from '@constants';
import { ParamsService } from '@core/service/params.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  prevUrl = '';
  urlChange$ = new BehaviorSubject<string>('');

  constructor(private router: Router, private paramsService: ParamsService, private location: Location) {
    this.location.onUrlChange((url) => {
      if (this.prevUrl !== url) {
        this.urlChange$.next(url);
      }
      this.prevUrl = url;
    });
  }

  #redirectToOrgRoute(orgId: string | undefined | null, route: string): Promise<boolean> {
    if (!!orgId) {
      return this.router.navigate([orgId, route]);
    }
    return this.router.navigate(['']);
  }

  redirectToRoot(): Promise<boolean> {
    return this.router.navigate(['']);
  }

  redirectToCatalog(orgId: string | undefined | null): Promise<boolean> {
    return this.#redirectToOrgRoute(orgId, RootNavigationRoute.catalog);
  }

  redirectToProducts(orgId: string | undefined | null): Promise<boolean> {
    return this.router.navigate([orgId, RootNavigationRoute.catalog, CatalogRoute.products]);
  }

  redirectToAnalytics(organizationId: string | undefined | null): Promise<boolean> {
    return this.#redirectToOrgRoute(organizationId, RootNavigationRoute.analytics);
  }

  redirectToOrganization(organizationId: string | undefined | null): Promise<boolean> {
    if (!!organizationId) {
      return this.router.navigate([organizationId]);
    }
    return this.router.navigate(['']);
  }

  redirectToProfile(organizationId: string | undefined | null): Promise<boolean> {
    return this.#redirectToOrgRoute(organizationId, 'profile');
  }

  redirectToSetup(organizationId: string | null): Promise<boolean> {
    return this.router.navigate([organizationId, 'setup']);
  }

  redirectToCashierPage(organizationId: string | null | undefined): Promise<boolean> {
    return this.#redirectToOrgRoute(organizationId, 'bonuses');
  }

  redirectToInvite(invitationId: string | null | undefined): Promise<boolean> {
    return this.router.navigate([AuthRoute.acceptInvitation, invitationId || '']);
  }

  redirectToInviteExpired(): Promise<boolean> {
    return this.router.navigate([AuthRoute.acceptInvitation, AuthRoute.expired]);
  }

  redirectToLogin(): Promise<boolean> {
    return this.router.navigate([AuthRoute.auth, AuthRoute.login]);
  }

  redirectToChooseOrganization(): Promise<boolean> {
    return this.router.navigate([AuthRoute.auth, AuthRoute.choose]);
  }

  redirectToChooseOrganizationV2() {
    this.router.navigate([AuthRoute.auth, AuthRoute.choose]);
  }

  redirectToPaymentRequired(organizationId: string): Promise<boolean> {
    return this.router.navigate([organizationId, 'payment-required']);
  }

  redirectToCatalogSettings(organizationId: string): Promise<boolean> {
    return this.router.navigate([organizationId, RootNavigationRoute.catalog, CatalogRoute.settings]);
  }

  redirectToCreateNew(activatedRoute: ActivatedRoute): Promise<boolean> {
    return this.router.navigate([ROUTE_CREATE_NEW], { relativeTo: activatedRoute });
  }

  redirectToItemPage(
    id: string | null | undefined,
    activatedRoute: ActivatedRoute,
    keepParams?: boolean,
    additionalRoute?: string,
  ): Promise<boolean> {
    const queryParams = keepParams ? this.paramsService.getRouterParams() : null;
    const routeArray = [id || id || ROUTE_CREATE_NEW];
    if (additionalRoute) {
      routeArray.push(additionalRoute);
    }

    return this.router.navigate(routeArray, { relativeTo: activatedRoute, queryParams });
  }

  redirectRelatively(path: string[], activatedRoute: ActivatedRoute, keepParams?: boolean): Promise<boolean> {
    const queryParams = keepParams ? this.paramsService.getRouterParams() : null;

    return this.router.navigate(path, { relativeTo: activatedRoute, queryParams });
  }

  redirectToParent(activatedRoute: ActivatedRoute) {
    return this.router.navigate(['../'], { relativeTo: activatedRoute });
  }

  redirectToYandex(organizationId: string | null, id: string) {
    if (organizationId) {
      this.router.navigate([organizationId, RootNavigationRoute.settings, SettingsRoute.applicationShop, id]);
    }
  }

  redirectToOrgSettings(organizationId: string): Promise<boolean> {
    return this.router.navigate([organizationId, RootNavigationRoute.settings, SettingsRoute.organization]);
  }

  redirectToImportProducts(orgId: string | null) {
    if (orgId) {
      this.router.navigate([orgId, RootNavigationRoute.catalog, CatalogRoute.products, ROUTE_IMPORT]);
    }
  }

  redirectToImportMenu(orgId: string | null, menuId: string) {
    if (orgId) {
      this.router.navigate([orgId, RootNavigationRoute.catalog, CatalogRoute.menus, menuId, ROUTE_IMPORT]);
    }
  }

  redirectToImportArrivals(orgId: string | null) {
    if (orgId) {
      this.router.navigate([orgId, RootNavigationRoute.warehouse, WarehouseRoute.arrivals, ROUTE_IMPORT]);
    }
  }

  redirectToImportLeftovers(orgId: string | null) {
    if (orgId) {
      this.router.navigate([orgId, RootNavigationRoute.warehouse, WarehouseRoute.leftovers, ROUTE_IMPORT]);
    }
  }

  redirectToImportSuppliers(orgId: string | null) {
    if (orgId) {
      this.router.navigate([orgId, RootNavigationRoute.warehouse, WarehouseRoute.suppliers, ROUTE_IMPORT]);
    }
  }

  redirectToImportCustomer(orgId: string | null) {
    if (orgId) {
      this.router.navigate([orgId, RootNavigationRoute.marketing, MarketingRoute.clients, ROUTE_IMPORT]);
    }
  }

  redirectToInvoices(organizationId: string): Promise<boolean> {
    return this.router.navigate([
      organizationId,
      RootNavigationRoute.settings,
      SettingsRoute.subscriptions,
      SettingsSubscriptionsRoute.invoices,
    ]);
  }

  redirectToAddPositions(activatedRoute: ActivatedRoute) {
    this.router.navigate([RootNavigationRoute.addPositions], { relativeTo: activatedRoute });
  }

  redirectToAddClient(activatedRoute: ActivatedRoute) {
    this.router.navigate([RootNavigationRoute.addClient], { relativeTo: activatedRoute });
  }

  openWarehousePage(orgId: string | null | undefined, id: string | undefined): void {
    if (orgId) {
      this.router.navigate([orgId, RootNavigationRoute.warehouse, WarehouseRoute.warehouses, id || ROUTE_CREATE_NEW]);
    }
  }

  openClientPage(orgId: string | null | undefined, id: string | undefined): void {
    if (orgId) {
      this.router.navigate([orgId, RootNavigationRoute.marketing, MarketingRoute.clients, id || ROUTE_CREATE_NEW]);
    }
  }

  openBookingPage(orgId: string | null | undefined, id: string): void {
    if (orgId) {
      this.router.navigate([orgId, RootNavigationRoute.marketing, MarketingRoute.onlineBookings, id]);
    }
  }

  openProductPage(orgId: string | null | undefined, id: string): void {
    if (orgId) {
      this.router.navigate([orgId, RootNavigationRoute.catalog, CatalogRoute.products, id]);
    }
  }

  openConfPage(orgId: string | null | undefined, id: string | undefined, appId: string): void {
    if (orgId) {
      this.router.navigate([
        orgId,
        RootNavigationRoute.settings,
        SettingsRoute.applicationShop,
        appId,
        // 'integrations',
        id || ROUTE_CREATE_NEW,
      ]);
    }
  }

  redirectToSubscription(orgId: string | null) {
    if (orgId) {
      this.router.navigate([orgId, RootNavigationRoute.settings, SettingsRoute.subscriptions, SettingsSubscriptionsRoute.info]);
    }
  }

  redirectToStore(orgId: string | null, id: string) {
    if (orgId) {
      this.router.navigate([orgId, RootNavigationRoute.settings, SettingsRoute.stores, id, SettingsSubscriptionsRoute.info]);
    }
  }
}
