import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SidebarMultiselectLayoutService } from '../sidebar-multiselect-layout/sidebar-multiselect-layout.service';

import { TableSidebarLayoutService } from './service/table-sidebar-layout.service';

@Component({
  selector: 'nm-table-sidebar-layout',
  templateUrl: './table-sidebar-layout.component.html',
  styleUrls: ['./table-sidebar-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSidebarLayoutComponent implements OnInit {
  @ViewChild('sidenavContainer', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChild('tableSidebar') tableSidebar: ElementRef;
  title = 'title';
  isLoading = false;
  readonly topBarHeight = 64;
  contentHeight$ = new BehaviorSubject('');

  constructor(
    public tableSidebarService: TableSidebarLayoutService,
    private cdRef: ChangeDetectorRef,
    private multiSelectService: SidebarMultiselectLayoutService,
  ) {}

  ngOnInit(): void {
    this.tableSidebarService.currentComponent.subscribe((component: unknown) => {
      setTimeout(() => {
        if (this.tableSidebar?.nativeElement.offsetHeight) {
          this.contentHeight$.next((this.tableSidebar.nativeElement.offsetHeight - this.topBarHeight).toString() + 'px');
        }
      });

      if (this.container) {
        this.container.clear();
      }
      if (component) {
        this.container.createComponent(component as ComponentFactory<unknown>);
        this.cdRef.detectChanges();
      }
    });
    this.tableSidebarService.title.subscribe((title) => {
      this.title = title;
      this.cdRef.detectChanges();
    });
    this.tableSidebarService.tableSidenavLoading.subscribe((isLoading) => {
      this.isLoading = isLoading;
      this.cdRef.detectChanges();
    });
  }
  onCloseSidebar() {
    if (this.tableSidebarService.getSidebarConfig()?.addPositionsComponent) {
      this.multiSelectService.close([]);
      return;
    }
    if (this.isLoading) {
      this.tableSidebarService.closeTableSidenav();
    } else {
      this.tableSidebarService.confirmExit();
    }
  }
  onExpandPage() {
    this.tableSidebarService.expandPage();
  }
}
