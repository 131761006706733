import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';

import { UserApi } from '@app/modules/employees/services/employees/user.api';
import { NavigationDrawerService } from '@app/shared/service/navigation-drawer.service';
import { ScopeNavigationRoute } from '@app/typings/role';
import { User } from '@app/typings/user';
import { SUPER_USER_KEY } from '@constants';
import { setContext, setUser } from '@sentry/angular';
import { SessionStorage } from '@services/api';
import { CatalogStorage } from '@services/catalog';
import { AccountApiService } from '@services/core';
import { RoleStorage } from '@services/employee';
import { OrganizationApi } from '@services/settings';
import { RedirectService, StoreControlsStorage } from '@services/shared';
import { Account, CoreSchema, MutationResult, OrganizationSite } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class AccountStorage {
  #account = new BehaviorSubject<Account | null>(null);
  account = this.#account.asObservable();
  accountFetched = new Subject<boolean>();
  isChangeAccount: Subject<boolean> = new Subject();

  // selected Organization
  // undefined - initial state
  // null - state when organization was deleted from state
  #organization = new BehaviorSubject<OrganizationSite | null | undefined>(undefined);
  organization = this.#organization.asObservable();

  // all Organizations of Account
  #organizations = new BehaviorSubject<OrganizationSite[] | null>(null);
  organizations = this.#organizations.asObservable();

  cacheOrganizations: Record<string, OrganizationSite[]> = {};

  #roles = new BehaviorSubject<string[]>([]);
  roles = this.#roles.asObservable();

  #isCasher = new BehaviorSubject<boolean>(false);
  isCasher = this.#isCasher.asObservable();

  #organizationSearchString = new BehaviorSubject<string | undefined>('');
  organizationSearchString = this.#organizationSearchString.asObservable();

  constructor(
    private accountApi: AccountApiService,
    private organizationApi: OrganizationApi,
    private userApi: UserApi,
    private roleStorage: RoleStorage,
    private sessionStorage: SessionStorage,
    private storeStorage: StoreControlsStorage,
    private catalogStorage: CatalogStorage,
    private redirectService: RedirectService,
    private navigationDrawerService: NavigationDrawerService,
  ) {}

  getOrganizationsCount(): number {
    return this.#organizations.getValue()?.length || 0;
  }

  getOrganization() {
    return this.#organization.getValue();
  }

  fetchAccount(): Observable<Account | null> {
    const subject = new Subject<Account | null>();

    const { accountId } = this.sessionStorage.getDecodedAccessToken();
    if (accountId) {
      this.organizationApi
        .getOrganizationSitesByAccount({ accountId, filter: {}, pageRequestInput: { page: 0, size: 100000 } })
        .subscribe((res) => {
          this.#organizations.next(res.data.organizationSitesByAccount.content);
        });
    } else {
      this.setupAccount(null);
      subject.next(null);
    }

    return subject;
  }

  setupAccount(account: Account | null) {
    this.setAccount(account);
    this.#setupSentry(account);
    if (account) {
      this.isChangeAccount.next(true);
    }
    setTimeout(() => {
      this.accountFetched.next(true);
    }, 0);
  }

  getAccount(): Account | null {
    return this.#account.getValue();
  }

  getRole(): string[] {
    return this.#roles.getValue();
  }

  setAccount(account: Account | null): void {
    this.#account.next(account);
  }

  getAccountId() {
    const { accountId } = this.sessionStorage.getDecodedAccessToken();
    return accountId;
  }

  setAccountV2(user: User): void {
    const { accountId, hasPassword, email, phone } = this.sessionStorage.getDecodedAccessToken();
    const { id, active, firstName, lastName, middleName, imageId, pin } = user;
    const account: Account = {
      id: accountId,
      active: active,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName || '',
      hasPassword,
      userId: id,
      workEmail: email || '',
      workPhone: phone || '',
      imageId: imageId || '',
      pin: pin || '',
    };

    this.setupAccount(account);
  }

  setSuperUserAccount() {
    const { accountId, hasPassword, email, phone } = this.sessionStorage.getDecodedAccessToken();
    const account: Account = {
      id: accountId,
      active: true,
      firstName: 'СуперЮзер',
      lastName: '',
      middleName: '',
      hasPassword,
      userId: '',
      workEmail: email || '',
      workPhone: phone || '',
      imageId: '',
      pin: '',
    };

    this.setupAccount(account);
  }

  updateAccount(accountInput: CoreSchema.AccountInput): MutationResult<'updateAccount'> {
    const $update = this.accountApi.updateAccount({ accountInput });

    $update.subscribe(() => {
      this.fetchAccount();
    });

    return $update;
  }

  #setupSentry(account: Account | null) {
    setUser(account);
  }

  getOrganizations(): void {
    const accountId = this.getAccountId();
    if (accountId) {
      this.organizationApi
        .getOrganizationSitesByAccount({ accountId: accountId, filter: {}, pageRequestInput: { page: 0, size: 100 } })
        .subscribe((res) => {
          const organizations = res.data.organizationSitesByAccount.content;
          this.#organizations.next(organizations);
          if (organizations.length) {
            if (organizations.length === 1) {
              this.setOrganization(organizations[0]);
              this.redirect();
            } else {
              this.redirectService.redirectToChooseOrganizationV2();
            }
          } else {
            this.createOrganization();
          }
        });
    }
  }

  redirect() {
    this.roles
      .pipe(
        skipWhile((result) => !result.length),
        take(1),
      )
      .subscribe((roles) => {
        if (!roles.includes(ScopeNavigationRoute.analytics) && !roles.includes('owner')) {
          return this.redirectService.redirectToProfile(this.sessionStorage.getOrgId());
        } else {
          return this.redirectService.redirectToAnalytics(this.sessionStorage.getOrgId());
        }
      });
  }

  getOrganizationsList(): void {
    this.organizationApi
      .getOrganizationSitesByAccount({ accountId: this.getAccountId(), filter: {}, pageRequestInput: { page: 0, size: 100000 } })
      .subscribe((res) => {
        const organizations = res.data.organizationSitesByAccount.content;
        this.#organizations.next(organizations);
      });
  }

  getUser(orgId: string) {
    this.userApi.iamUser({ organizationId: orgId }).subscribe((res) => {
      const user = res.data.iamUser;
      if (user) {
        this.setAccountV2(user);
        this.getRoles(user.id);
      }
    });
  }

  getRoles(id: string) {
    this.roleStorage.rolesByUser({ userId: id, pageRequestInput: { page: 0, size: 1000 } }).subscribe((res) => {
      const content = res.data.rolesByUser.content;
      if (content) {
        const roles: string[] = [];
        content.forEach((c) => roles.push(...c.scopes));

        this.updateRoles(roles);
      }
    });
  }

  getOrganizationById(id: string | undefined): void {
    if (!id) return;
    this.organizationApi.organizationSite({ id }).subscribe((res) => {
      if (res.data.organizationSite) {
        this.sessionStorage.setCurrencyUnit(res.data.organizationSite.currency);
        this.#setActiveOrganization(res.data.organizationSite);
        this.setOrganization(res.data.organizationSite);
      }
    });
  }

  #setActiveOrganization(data: OrganizationSite | null): void {
    this.#organization.next(data);
  }

  updateOrganizations(data: OrganizationSite[]): void {
    const cached = this.#organizationSearchString.value ? (this.cacheOrganizations[this.#organizationSearchString.value] = []) : [];
    const orgs: OrganizationSite[] = data ? [...cached, ...data] : data;

    this.#organizations.next(orgs);
    this.#setCacheOrganization(orgs);
    this.#setDefaultOrganization();
  }

  #setCacheOrganization(data: OrganizationSite[]) {
    if (this.#organizationSearchString.value) {
      this.cacheOrganizations[this.#organizationSearchString.value] = data;
    }
  }

  #setDefaultOrganization() {
    const localOrgId = this.getLocalOrganizationId();
    const currentOrganization = this.#organization.getValue() || null;

    if (localOrgId && !currentOrganization) {
      const organization = (this.#organizations.value || []).find(({ id }) => id === localOrgId);

      if (organization) {
        this.setOrganization(organization);
      } else {
        this.getOrganizationById(localOrgId);
      }

      this.storeStorage.initAllStores();
    }
  }

  getLocalOrganizationId(): string | null {
    return this.sessionStorage.getOrgId();
  }

  setOrganization(organization: OrganizationSite): void {
    this.catalogStorage.setCatalog(this.catalogStorage.getCatalog());
    if (localStorage.getItem(SUPER_USER_KEY) === 'false') {
      this.getUser(organization.id);
    } else {
      this.setSuperUserAccount();
      this.updateRoles(['owner']);
    }
    this.sessionStorage.setOrgId(organization.id);
    if (organization) {
      this.sessionStorage.setCurrencyUnit(organization?.currency);
    }
    this.setSentryOrganization(organization);
    this.#setActiveOrganization(organization);
  }

  setSentryOrganization({ id, name }: OrganizationSite): void {
    setContext('organization', { id, name });
  }

  removeOrganization(): void {
    this.sessionStorage.removeOrgId();
    this.sessionStorage.removeCurrencyUnit();
    this.sessionStorage.removeFilters();

    this.#setActiveOrganization(null);
  }

  removeOrganizations(): void {
    this.#organizations.next(null);
  }

  updateRoles(roles: string[]): void {
    this.#roles.next(roles);
  }

  createOrganization() {
    const { accountId } = this.sessionStorage.getDecodedAccessToken();
    return this.organizationApi.createOrganizationSite({ accountId }).subscribe((res) => {
      this.sessionStorage.updateTokens().then(() => {
        if (res && res.data) {
          const org = res.data.createOrganizationSite;
          if (org.createdOrganization && org.createdOrganization.id) {
            this.#organizations.next([org.createdOrganization]);
            this.setOrganization(org.createdOrganization);
            this.redirect();
            // this.redirectService.redirectToSetup(org.createdOrganization.id);
          }
        }
      });
    });
  }

  setOrganizations(orgs: OrganizationSite[]) {
    this.#organizations.next(orgs);
  }

  clear(): void {
    this.removeOrganization();
    this.removeOrganizations();
    this.updateRoles([]);
    this.setupAccount(null);
  }

  getIsCacher(): boolean {
    return this.#isCasher.getValue();
  }
}
